// Here you can add other styles
.full-width {
   width: 100%;
}

.avatar-img {
   width: 50px;
   height: 50px;
   border-radius: 50em;
}

.profile-img {
   border-radius: 50%;
   width: 65px;
   height: 60px;
}

.pull-right {
   float: right;
}


.profile-table th {
   padding: 5px
}

.profile-table td {
   padding: 5px
}

.register-bg {
   background-image: url('../assets/images/register.jpg');
   background-size: contain;
   background-repeat: no-repeat;
}

.nav-link {
   font-family: PoppinsSemiBold;
}

.bg-light {
   background-color: #fff !important;
}

.vehicle-types-cards {
   background-color: #fff;
   height: 125px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 15px !important;
   align-items: center !important;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   text-align: center;
   border-radius: 10px;
   text-decoration: none;
}

.vehicle-types-cards span {
   font-family: PoppinsSemiBold;
   color: $black
}

.quotation-list-header {
   background-color: #fff;
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 15px !important;
   align-items: center !important;
   //  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   text-align: center;
   border: 0.5px solid #d3d3d3;
   text-decoration: none;
}

h4 {
   font-family: PoppinsSemiBold;
}

.subtitle {
   font-family: PoppinsLight;
}

.w-50 {
   width: 50%;
}

.b-right {
   border-right: 1px solid #d3d3d3;
}

.card-header {
   background-color: #fff;
   display: inline-block;
   padding: 15px;
   ;
}


.card-heading {
   display: inline-block;
}

.btn-light-blue {
   background-color: #0A6FE8;
   color: #FFF;
   font-family: PoppinsSemiBold;
}

.btn-light-blue:hover {
   background-color: #0A6FE8;
   opacity: 0.9;
   color: #FFF;
   font-family: PoppinsSemiBold;
}


.saved-quotation-list-card {
   border-bottom: 1px solid #d3d3d3;
   display: flex;
   flex-direction: row;
   padding: 15px;
   text-decoration: none;

}

.saved-quotation-list-card .logo-cotainer {
   width: 10%;

}

.saved-quotation-list-card .details-container {
   width: 70%;
   text-align: left;
   display: flex;
   flex-direction: column;
}

.saved-quotation-list-card .time-container {
   width: 23%;
   text-align: right;
   display: flex;
   flex-direction: column;
   color: #000;
}


.saved-quotation-list-card .time-container .bell-icon {
   height: 21px;
   width: 21px;
   align-self: end;
   color: #000;

}

.qtl-vehicle-make {
   font-family: PoppinsSemiBold;
   font-size: 21px;
   color: #000;

}

.qtl-vehicle-model {
   font-family: PoppinsLight;
   color: #0A6FE8;
}

.qtl-vehicle-rto {
   font-family: PoppinsLight;
   color: #000;

}

.btn-fill {
   background-color: #1267c1;
   border: 1px solid #1267c1;
   color: #FFF;
   font-family: PoppinsSemiBold;

}

.btn-fill:hover {
   background-color: #0A6FE8;
   border: 1px solid #0A6FE8;
   color: #FFF;
}

.mr-10 {
   margin-right: 10px;
}

.btn-outline {
   background-color: #FFF;
   border: 1px solid #1267c1;
   color: #1267c1;
   font-family: PoppinsLight;

}


.btn-outline:hover {
   background-color: #0A6FE8;
   border: 1px solid #0A6FE8;
   color: #fff;
   font-family: PoppinsLight;

}

.label {
   color: #0876BE;
   font-size: 13px;
   font-family: PoppinsSemiBold;
}

.normal-text {
   color: #000;
   font-size: 13px;
   font-family: PoppinsRegular;
}

.select-box {
   font-family: PoppinsRegular;
}

.link-btn {
   text-decoration: none !important;
   padding: 10px !important;
   border-radius: 25px !important;
}



.suggestion-quotation-list-card {
   border: 0.5px solid #d3d3d3;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   border-radius: 5;

}

.suggestion-quotation-list-card .insurer-details {
   display: flex;
   flex-direction: row;
   padding: 15px;


}

.suggestion-quotation-list-card .payout-details {
   display: flex;
   flex-direction: row;
   border-top: 0.5px solid #d3d3d3;
   padding: 15px;


}

.suggestion-quotation-list-card .payout-details .payout {
   width: 33.33%
}

.suggestion-quotation-list-card .payout-details .discount {
   width: 33.33%
}

.suggestion-quotation-list-card .payout-details .no-add-on-plan {
   width: 33.33%
}

.suggestion-quotation-list-card .payout-details .payout span {

   margin-right: 10px;
   font-family: PoppinsRegular;
}

.suggestion-quotation-list-card .payout-details .discount span {

   margin-right: 10px;
   font-family: PoppinsRegular;
}


.suggestion-quotation-list-card .payout-details .no-add-on-plan span {
   float: right;
   font-family: PoppinsRegular;
}


.suggestion-quotation-list-card .logo-cotainer {
   width: 20%;

}

.suggestion-quotation-list-card .details-container {
   width: 70%;
   text-align: left;
   display: flex;
   flex-direction: column;
}

.suggestion-quotation-list-card .time-container {
   width: 23%;
   text-align: right;
   display: flex;
   flex-direction: column;

}


.suggestion-quotation-list-card .time-container .next-btn {
   height: 50px;
   width: 50px;
   align-self: end;
}


.proposal-summary th td {
   // border: 0.5px solid #d3d3d3;
   padding: 10px;
   border-collapse: collapse;
}


.view-more-btn {
   font-family: PoppinsSemiBold;
   color: #fff
}

.view-more-btn:hover {
   font-family: PoppinsSemiBold;
   color: #fff
}

.view-more-dropdown-menu {
   font-family: PoppinsSemiBold;
}

.cover-dropdown-item:hover {
   background-color: #d3d3d3;
   color: #000;
   border-radius: 5px;
}

.add-on-cover-plans {
   background-color: lightgreen;
   color: #000;
   border-radius: 5px;
   margin-right: 10px;
   padding: 5px 10px;
   margin-top: 5px;
   width: auto;
   display: inline-block;
   font-size: 11px;
   font-family: PoppinsSemiBold;


}


.select__menu {
   z-index: 20 !important;
}

.back-btn {
   border-radius: 5px !important;
   // height: 15;
   padding: 5px 10px !important;
}

.card-sub-heading {
   display: inline-block;
   font-size: 18px;
   font-family: PoppinsRegular;

}



.profile-label {
   color: #0876BE;
   font-size: 15px;
   font-family: PoppinsSemiBold;
}

.profile-label-value {
   color: #000;
   font-size: 15px;
   font-family: PoppinsRegular;
}



.dashboard-box-container {
   background-color: #fff;
   padding: 10px;
   border-radius: 10px;
   margin-bottom: 10px;

   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboard-icon {
   height: 70px;
   width: 70px;
}

.widdget-heading {
   font-family: PoppinsSemiBold;
   font-size: 25px;
   margin-bottom: 0px;
   display: block;
}

.widdget-sub-heading {
   font-family: PoppinsLight;
   font-size: 16px;
   display: block;

}

.widget-header {
   height: 90px;
   padding: 10px;
}

.widget-header div {
   display: inline-block;
}

.widget-heading-container {
   // padding-top: 10px;
}


.widget-heading-container div {
   display: block;
}

.widget-body {}

.widget-body-item div {
   display: inline-block;
}


.widget-body-item div {
   width: 33.33%;
   display: inline-block;

}


.widget-body-item div:first-child {
   text-align: left;
   font-family: PoppinsRegular;
}

.widget-body-item div:nth-child(2) {
   text-align: center;
   font-family: PoppinsLight;


}

.widget-body-item div:last-child {
   text-align: right;
   font-family: PoppinsRegular;

}


.container {
   align-items: center;
   justify-content: center;
}

.image {

   float: left;
}

.text {
   font-size: 20px;
   padding-left: 20px;
   // padding-top: 20%;
   float: left;
}

.login-logo {
   position: absolute;
   top: 50%;
   transform: translate(0, -50%);
   left: 0;
   right: 10px;
}

.login-logo-img {
   height: 120px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
   .login-logo-img {
      height: 65px;
   }
}


.login_details_wrapper {
   width: 100%;
   padding: 20px 0px;
}

.login_text_wrapper {
   max-width: calc(100% - 90px);
   display: inline-block;
   font-family: PoppinsRegular;
   color: #000 !important;
   justify-content: center;
   font-size: 18px;
   padding-left: 10px;

}

.login_img_wrapper {
   display: inline-block;
}

.login_btn {
   background-color: #1267c1;
   width: 100%;
}



.sidebar {
   background-color: #fff;
   border-right: 1px solid #d8dbe0 !important;
}

.sidebar-nav .nav-icon {
   color: #212b36 !important;
}

.sidebar-nav .nav-link.active .nav-icon {
   color: #fff !important;
}

.sidebar-nav .nav-link.active {
   color: #fff !important;
   font-family: PoppinsRegular;
   background-color: #1267c1;
   border: 1px solid #1267c1;
   // margin: 0px 10px;

   border-radius: 10px;


}

.sidebar-nav .nav-item {
   padding: 5px 10px 5px 10px;
}

.sidebar-nav .nav-link:hover {
   color: #fff !important;
   font-family: PoppinsRegular;
   background-color: #1267c1;
   border: 1px solid #1267c1;
   // margin: 0px 10px;
   border-radius: 10px;


}


.sidebar-nav .nav-link:hover .nav-icon {
   color: #fff !important;
}



.sidebar-nav .nav-link {
   color: #212b36 !important;
   font-family: PoppinsRegular;

}

.sidebar-nav .nav-title {
   color: #212b36 !important;
   font-family: PoppinsSemiBold;


}


.sidebar-brand {
   background-color: #fff;
}

.sidebar-toggler {
   background-color: #fff;

}

.sidebar-toggler {
   display: none !important;
}

@media (max-width: 768px) {
   .app-header-mobile-logo {
      display: none !important;
   }

}



.view-more-btn {
   font-family: PoppinsSemiBold;
   color: #fff
}

.view-more-btn:hover {
   font-family: PoppinsSemiBold;
   color: #fff
}

.view-more-dropdown-menu {
   font-family: PoppinsSemiBold;
}



.active-category {
   background-color: #1267c1;
   color: #fff;
   border: 1px solid #1267c1;
}

.active-category span {
   color: #fff;
}

.vehicle-types-cards:hover {
   background-color: #1267c1;
   border: 1px solid #1267c1;
   color: #fff !important;

}

.vehicle-types-cards:hover span {
   color: #fff !important;

}



.login-cards-container {
   width: 70%;
}

.login-slider .item {
   height: 450px;
   width: 100%;
   color: #fff;
   line-height: 160px;
   text-align: center;
   background: #364d79;
   border-top-left-radius: 5;
   border-bottom-left-radius: 5;
}


@media (max-width: 500px) {
   .login-slider .item {
      height: 250px;
      width: 100%;
      color: #fff;
      line-height: 160px;
      text-align: center;
      background: #364d79;
      border-top-left-radius: 5;
      border-top-right-radius: 5;
      border-bottom-left-radius: 0;
   }


   .login-img-card {
      margin-bottom: 0px !important;
   }


   .login-cards-container {
      width: 80%;
      //    box-shadow: -3px 4px 46px 0px rgba(0,0,0,0.75);
      // -webkit-box-shadow: -3px 4px 46px 0px rgba(0,0,0,0.75);
      // -moz-box-shadow: -3px 4px 46px 0px rgba(0,0,0,0.75);
   }

   .login-form-card {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-width: 0px;
   }

   .countdown-text p {
      font-size: 11px;
   }

   .countdown-text a {
      font-size: 11px;
   }
}


.card-heading {
   display: inline-block;
   color: #0876BE;
   font-size: 1.5rem;
   font-weight: 400;
}

.E_table {
   border-collapse: initial;
   border-spacing: 30px 10px;
}