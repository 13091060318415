// If you want to override variables do it here
@font-face {
    font-family: "PoppinsSemiBold";
    src: local("PoppinsSemiBold"),
     url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
 }


@font-face {
    font-family: "PoppinsLight";
    src: local("PoppinsLight"),
     url("../assets/fonts/Poppins-Light.ttf") format("truetype");
 }

@font-face {
    font-family: "PoppinsRegular";
    src: local("PoppinsRegular"),
     url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
 }


@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

 
